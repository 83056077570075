* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
}

.home,
.skills,
.projects,
.contact,
.resume {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}
.home1 {
  display: flex;
  height: 60vh;
  top: -20vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}
.loaderScreen {
  height: 100vh;
  /* background: #123234; */
}

.loadingText {
  color: #e086a6;
  display: flex;
  position: relative;
  top: -25vh;
  align-items: center;
  z-index: 2;
  justify-content: center;
  font-size: 3rem;
}
.loadingText h2 {
  margin-top: 5vh;
  font-size: 20px;
}

.waves {
  position: bottom;
  width: 100%;
  height: 45vh;
  margin-bottom: -7px;
  min-height: 100px;
  max-height: 150px;
}

.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}
@media (max-width: 768px) {
  .waves {
    height: 25vh;
    min-height: 40px;
  }
  .loadingText h2 {
    font-size: 15px;
  }
}
.home1 h1 {
  color: white;
}
.loader {
  display: flex;
  position: relative;
  top: -15vh;
  align-items: center;
  z-index: 2;
  justify-content: center;
  font-size: 3rem;
}

.skills {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.projects {
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.contact {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
